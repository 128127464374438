import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Container = 'relative w-full'
const innerDiv = 'md:h-36 bg-white flex flex-col items-center justify-center mx-auto mb-6 lg:mb-12'

const DistributorSectionNew = () => {
  const data = useStaticQuery(graphql`
    query DistributorSectionNewQuery {
      prismicDistributorPage {
        data {
          section_title
          featured_distributors {
            distributor_logo {
              gatsbyImageData(width: 380)
              alt
            }
            distributor_website {
              link_type
              url
            }
          }
        }
      }
    }
  `)

  const doc = data.prismicDistributorPage.data
  const dist = doc.featured_distributors

  return (
    <div id='featured-dist' className='bg-white pt-20 pb-6 sm:px-4'>
      <div className='w-11/12 xl:max-w-screen-xl flex flex-col items-center font-body justify-center mx-auto -mt-48 md:-mt-16 lg:-mt-8 xl:-mt-2 mb-12'>
        <h2 className='font-display text-3xl md:text-4xl text-red-900 text-center tracking-wider leading-tight'>{doc.section_title}</h2>
      </div>

      <div className='w-11/12 xl:max-w-screen-xl grid grid-cols-2 md:grid-cols-4 gap-8 place-content-center mx-auto mt-24 mb-6'>
        {dist.map(item => (
          <div className={Container}>
            <div className={innerDiv}>
              <a href={item.distributor_website.url} target='_blank' rel='noopener noreferrer'>
                <GatsbyImage
                  image={getImage(item.distributor_logo)}
                  alt={item.distributor_logo.alt ? item.distributor_logo.alt : ""}
                />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DistributorSectionNew