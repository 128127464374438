import React from "react"
import { useStaticQuery, graphql } from 'gatsby'

import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"
import DistributorMapSectionUpdate from "../../components/sections/DistributorMapSectionUpdate"

const DistributorsPage = () => {
  const data = useStaticQuery(graphql`
    query DistributorsPageExampleQuery {
      prismicDistributorPage {
        data {
          meta_title
          meta_description
        }
      }
    }
  `)

  const doc = data.prismicDistributorPage.data

  return (
    <MainContainer>
      <HeadDataNew 
        title={doc.meta_title}
        description={doc.meta_description}
      />

      <DistributorMapSectionUpdate />
    </MainContainer>
  )
}

export default DistributorsPage