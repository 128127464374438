import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const sectionWrapper = 'w-11/12 md:w-8/12 lg:w-6/12 xl:w-5/12 xl:max-w-screen-sm mx-auto my-16'
const stateWrapper = 'flex items-center font-body justify-center mb-4 border-b border-red-100'
const stateName = 'font-display text-4xl md:text-5xl text-red-900 text-center tracking-wider'
const storeWrapper = 'flex flex-col items-start'
const Container = 'w-full mb-2'
const innerDiv = 'bg-white flex flex-col rounded my-3 p-4 overflow-hidden shadow-lg'
const storeName = 'text-2xl text-red-900 leading-tight mb-2'
const storeAddress = 'mt-4'
const storeContact = 'mt-0'

const DistributorListSectionUpdate = () => {
  const data = useStaticQuery(graphql`
    query DistributorListSectionUpdateQuery {
      allPrismicDistributorCompany {
        edges {
          node {
            data {
              company_name
              company_website
              company_details {
                state
                address
                phone
                email
              }
            }
          }
        }
      }
    }
  `)

  const allDistributors = data.allPrismicDistributorCompany.edges

  return (
    <div>
      <div id='AZ' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Arizona</h2>
        </div>

        <div className={storeWrapper}>
        
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data
            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const arizonaDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Arizona"
            );

            if (arizonaDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {arizonaDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='CA' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>California</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const californiaDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "California"
            );

            if (californiaDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {californiaDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='CO' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Colorado</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const coloradoDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Colorado"
            );

            if (coloradoDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {coloradoDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='FL' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Florida</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const floridaDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Florida"
            );

            if (floridaDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {floridaDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='GA' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Georgia</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const georgiaDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Georgia"
            );

            if (georgiaDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {georgiaDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='IL' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Illinois</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const illinoisDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Illinois"
            );

            if (illinoisDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {illinoisDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='IA' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Iowa</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const iowaDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Iowa"
            );

            if (iowaDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {iowaDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='KS' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Kansas</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const kansasDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Kansas"
            );

            if (kansasDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {kansasDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='KY' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Kentucky</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const kentuckyDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Kentucky"
            );

            if (kentuckyDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {kentuckyDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='LA' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Louisiana</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const louisianaDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Louisiana"
            );

            if (louisianaDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {louisianaDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='MD' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Maryland</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const marylandDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Maryland"
            );

            if (marylandDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {marylandDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='MA' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Massachusetts</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const massachusettsDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Massachusetts"
            );

            if (massachusettsDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {massachusettsDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='MI' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Michigan</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const michiganDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Michigan"
            );

            if (michiganDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {michiganDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='MN' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Minnesota</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const minnesotaDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Minnesota"
            );

            if (minnesotaDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {minnesotaDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='NE' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Nebraska</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const nebraskaDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Nebraska"
            );

            if (nebraskaDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {nebraskaDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='NV' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Nevada</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const nevadaDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Nevada"
            );

            if (nevadaDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {nevadaDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='NJ' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>New Jersey</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const newJerseyDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "New Jersey"
            );

            if (newJerseyDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {newJerseyDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='NM' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>New Mexico</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const newMexicoDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "New Mexico"
            );

            if (newMexicoDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {newMexicoDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='NY' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>New York</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const newYorkDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "New York"
            );

            if (newYorkDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {newYorkDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='NC' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>North Carolina</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const northCarolinaDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "North Carolina"
            );

            if (northCarolinaDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {northCarolinaDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='ND' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>North Dakota</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const northDakotaDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "North Dakota"
            );

            if (northDakotaDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {northDakotaDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='OH' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Ohio</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const ohioDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Ohio"
            );

            if (ohioDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {ohioDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='OK' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Oklahoma</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const oklahomaDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Oklahoma"
            );

            if (oklahomaDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {oklahomaDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='SD' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>South Dakota</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const southDakotaDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "South Dakota"
            );

            if (southDakotaDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {southDakotaDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='TN' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Tennessee</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const tennesseeDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Tennessee"
            );

            if (tennesseeDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {tennesseeDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='TX' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Texas</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const texasDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Texas"
            );

            if (texasDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {texasDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='UT' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Utah</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const utahDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Utah"
            );

            if (utahDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {utahDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='VA' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Virginia</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const virginiaDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Virginia"
            );

            if (virginiaDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {virginiaDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='WA' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Washington</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const washingtonDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Washington"
            );

            if (washingtonDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {washingtonDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='WI' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Wisconsin</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const wisconsinDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Wisconsin"
            );

            if (wisconsinDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {wisconsinDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <div id='ON' className={sectionWrapper}>
        <div className={stateWrapper}>
          <h2 className={stateName}>Ontario</h2>
        </div>

        <div className={storeWrapper}>
          {allDistributors.map(allDistributor => {
            const distributorData = allDistributor.node.data

            const companyName = distributorData.company_name
            const companyWebsite = distributorData.company_website

            // Filter and map through company_details array
            const ontarioDistributors = distributorData.company_details.filter(
              companyDetail => companyDetail.state === "Ontario"
            );

            if (ontarioDistributors.length > 0) {
              return (
                <div key={companyName} className={Container}>
                  <div className={innerDiv}>
                    <h2 className={storeName}>{companyName}</h2>
                    {ontarioDistributors.map((companyDetail, index) => (
                      <div key={index}>
                        {companyDetail.address
                          ?
                          <p className={storeAddress}>{companyDetail.address}</p>
                          :
                          null
                        }
                        {companyDetail.phone
                          ?
                          <p className={storeContact}>{companyDetail.phone}</p>
                          :
                          null
                        }
                        {companyDetail.email
                          ?
                          <p className={storeContact}>{companyDetail.email}</p>
                          :
                          null
                        }
                      </div>
                    ))}
                    {companyWebsite
                      ?
                      <p className={storeAddress}>{companyWebsite}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default DistributorListSectionUpdate