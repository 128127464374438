import React from 'react'
import USAMap from "react-usa-map"
import { navigate } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import DistributorSectionNew from './DistributorSectionNew'
import DistributorListSectionUpdate from './DistributorListSectionUpdate'

const StyledMapDiv = styled.div`
  &#distmap-section path {
    pointer-events: all;

    &:hover {
      opacity: 1;
    }

    &.CA.state,
    &.NV.state,
    &.AZ.state,
    &.UT.state,
    &.NM.state,
    &.CO.state,
    &.WA.state,
    &.OK.state,
    &.TX.state,
    &.MO.state,
    &.IA.state,
    &.MN.state,
    &.TN.state,
    &.LA.state,
    &.IL.state,
    &.KY.state,
    &.OH.state,
    &.GA.state,
    &.MI.state,
    &.NC.state,
    &.FL.state,
    &.VA.state,
    &.MD.state,
    &.NY.state,
    &.NJ.state,
    &.MA.state,
    &.ID.state,
    &.WY.state,
    &.ND.state,
    &.SD.state,
    &.NE.state,
    &.KS.state,
    &.AR.state,
    &.WI.state,
    &.MS.state,
    &.AL.state,
    &.SC.state,
    &.WV.state,
    &.PA.state,
    &.VT.state,
    &.NH.state,
    &.ME.state,
    &.RI.state,
    &.CT.state,
    &.OR.state,
    &.MT.state,
    &.IN.state {
      fill: #231f20;

      &:hover {
        opacity: 1;
        fill: #981d22;      
        cursor: pointer;
      }
    }
  }
`

const DistributorMapSectionUpdate = () => {
  const data = useStaticQuery(graphql`
    query CDistributorMapSectionUpdateQuery {
      prismicDistributorPage {
        data {
          page_title
          page_instructions
          page_text_for_canada_link
          meta_title
          meta_description
        }
      }
    }
  `)

  /* mandatory */
  const mapHandler = (event) => {
    if (
      event.target.dataset.name === 'OR' ||
      event.target.dataset.name === 'ID' ||
      event.target.dataset.name === 'WY' ||
      event.target.dataset.name === 'AR' ||
      event.target.dataset.name === 'MS' ||
      event.target.dataset.name === 'AL' ||
      event.target.dataset.name === 'SC' ||
      event.target.dataset.name === 'WV' ||
      event.target.dataset.name === 'PA' ||
      event.target.dataset.name === 'VT' ||
      event.target.dataset.name === 'NH' ||
      event.target.dataset.name === 'ME' ||
      event.target.dataset.name === 'RI' ||
      event.target.dataset.name === 'CT' ||
      event.target.dataset.name === 'IN' ||
      event.target.dataset.name === 'MT' ||
      event.target.dataset.name === 'MO'
      ) {
      navigate(`#featured-dist`)
    } else {
      navigate(`#${event.target.dataset.name}`)
    }
  }

  const doc = data.prismicDistributorPage.data

  return (
    <div className='bg-white py-12'>
      <div className='w-11/12 xl:max-w-screen-xl flex flex-col items-center font-body justify-center mx-auto -mb-24 md:mb-4'>
        <h1 className='font-display text-4xl md:text-5xl text-red-900 text-center tracking-wider leading-tight'>{doc.page_title}</h1>
        <p className='w-full md:w-2/3 xl:w-6/12 text-center'>{doc.page_instructions}<br/><a href='#ON' onClick={() => navigate('#ON')} className='underline'>{doc.page_text_for_canada_link}</a></p>
      </div>

      <StyledMapDiv id='distmap-section' className="w-full md:w-10/12 flex justify-center mx-auto">
        <USAMap onClick={mapHandler} />
      </StyledMapDiv>

      <DistributorSectionNew />

      <DistributorListSectionUpdate />
    </div>
  )
}

export default DistributorMapSectionUpdate